$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".hamburger").removeClass("is-active");
        $("#main-wrapper, .overlay").removeClass("off-canvas-active");
        $("#off-canvas").removeClass("active");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $(".popup").removeClass("active");
    }
});

$(".overlay").click(function () {
    $(".hamburger").removeClass("is-active");
    $("#main-wrapper, .overlay").removeClass("off-canvas-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
    $(".popup").removeClass("active");
});

$(".header-mainnav").mouseout(function () {
    $(".overlay").removeClass("menu-active");
});

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");


    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

// When the user clicks on the button, scroll to the top of the document
function topFunction() {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

$(function() {
    $('.scroll-down').click (function() {
      $('html, body').animate({scrollTop: $('div.first').offset().top }, 'slow');
      return false;
    });
  });
